<template>
    <VModal name="statisticsModal" width="100%" height="100%" :scrollable="true">
        <div class="vm-header">
            <div class="vm-header-left">
            </div>
            <div class="vm-header-center">
                {{ statisticName }}
            </div>
            <div class="vm-header-right">
                <img src="@/assets/ic_nav_close.svg" alt="Close Button" @click="dismissDialog">
            </div>
        </div>
        <div class="vm-content">

            <!-- Tabs -->
            <div class="tabs tabs--fullwidth">
                <div class="tabs__content">
                    <v-btn @click="displayTab('entire')" :class="activeDisplay === 'entire' ? 'is-active' : ''" text v-ripple="false">Gesamt</v-btn>
                    <v-btn @click="displayTab('week')" :class="activeDisplay === 'week' ? 'is-active' : ''"  text v-ripple="false">Woche</v-btn>
                    <v-btn @click="displayTab('month')" :class="activeDisplay === 'month' ? 'is-active' : ''" text v-ripple="false">Monat</v-btn>
                    <v-btn @click="displayTab('year')" :class="activeDisplay === 'year' ? 'is-active' : ''" text v-ripple="false">Jahr</v-btn>
                </div>
            </div>

            <!-- Calendar Navigation -->
            <div v-if="activeDisplay !== 'entire'" class="calendar-navigation">
                <div class="calendar-navigation__content">
                    <a @click="showPrevDateArrow && prevDate()">
                        <div class="calendar-navigation__left">
                            <img src="@/assets/ic_nav_back.svg" alt="Preview Date Arrow" :style="showPrevDateArrow ? '' : 'opacity: .12'" >
                        </div>
                    </a>
                    <div class="calendar-navigation__center">
                        <span>{{ getDisplayDate }}</span>
                    </div>
                    <a @click="showNextDateArrow && nextDate()">
                        <div class="calendar-navigation__right">
                            <img src="@/assets/ic_nav_next.svg" alt="Next Date Arrow" :style="showNextDateArrow ? '' : 'opacity: .12'" >
                        </div>
                    </a>
                </div>
            </div>

            <!-- Content -->
            <div class="statistics__detail">
                <div class="statistics__detail__header">
                    <div v-if="statisticName === 'Trainingsdauer'" class="statistics__detail__header__value">
                        {{ totalHours.toLocaleString() }}<span>h</span> {{ totalMinutes }}<span>m</span>
                    </div>
                    <div v-else class="statistics__detail__header__value">
                        {{ total.toLocaleString() }} {{ currentUnit }}
                    </div>
                    <div class="statistics__detail__header__label">
                        Gesamt
                    </div>
                </div>
                <div id="graphContainer" class="statistics__detail__chart" @click="clickBar">
                    <BarGraph class="statistics__detail__graph" :width="graphWidth()"  :currentLabel="currentLabel" :points="values" :startYear="startYear" />
                </div>
                <div class="list">
                    <div class="list__item">
                        <div class="list__item__content">
                            <div class="list__item__label list__item__label--light">
                                Ø {{ statisticName }}
                            </div>
                            <div v-if="statisticName !== 'Trainingsdauer'" class="list__item__value">
                                {{ average.toLocaleString() }} {{ currentUnit }}
                            </div>
                            <div v-else class="list__item__value">
                                {{ getHours(average).toLocaleString() }}:{{ getMinutes(average) }} {{ currentUnit }}
                            </div>
                        </div>
                    </div>
                    <div class="list__item">
                        <div class="list__item__content">
                            <div class="list__item__label list__item__label--light">
                                Max. {{ statisticName }}
                            </div>
                            <div v-if="statisticName !== 'Trainingsdauer'" class="list__item__value">
                                {{ max.toLocaleString() }} {{ currentUnit }}
                            </div>
                            <div v-else class="list__item__value">
                                {{ getHours(max).toLocaleString() }}:{{ getMinutes(max)}} {{ currentUnit }}
                            </div>
                        </div>
                    </div>
                    <div class="list__item">
                        <div class="list__item__content">
                            <div class="list__item__label list__item__label--light">
                                Min. {{ statisticName }}
                            </div>
                            <div v-if="statisticName !== 'Trainingsdauer'" class="list__item__value">
                                {{ min.toLocaleString() }} {{ currentUnit }}
                            </div>
                            <div v-else class="list__item__value">
                                {{ getHours(min).toLocaleString() }}:{{ getMinutes(min) }} {{ currentUnit }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </VModal>
</template>

<script>

import BarGraph from "@/components/BarGraph.vue";
import moment from "moment";

export default {
    name: 'StatisticsModal',
    components: {
        BarGraph,
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            // reset values
            this.activeDisplay = this.selectedActiveDisplay;
            this.date = this.dateParameter;
            this.loadData();
        },
        graphWidth() {
            var elem = document.getElementById("graphContainer");
            if (elem) {
                return elem.offsetWidth;
            }
            return 320;
        },
        loadData() {
            this.$store.dispatch('statisticsData', {startDate: this.date, scope: this.activeDisplay}).then( (res) => {
                this.minDate = res.minDate;
                if (this.statisticName === "Trainingsdauer") {
                    this.values = res.data.map(el => el.duration);
                    this.average = Math.round(res.avgDuration);
                    this.max = Math.round(res.maxDuration);
                    this.min = Math.round(res.minDuration);
                    this.totalHours = Math.floor(res.totalDuration / 60);
                    this.totalMinutes = Math.round(res.totalDuration % 60);
                }
                else if (this.statisticName === "Sessions") {
                    this.values = res.data.map(el => el.numberOfWorkouts);
                    this.average = Math.round(res.avgCount);
                    this.max = Math.round(res.maxCount);
                    this.min = Math.round(res.minCount);
                    this.total = res.totalCount;
                }
                else if (this.statisticName === "Aktivitätskalorien") {
                    this.values = res.data.map(el => el.energyExpended);
                    this.average = Math.round(res.avgEnergy);
                    this.max = Math.round(res.maxEnergy);
                    this.min = Math.round(res.minEnergy);
                    this.total = Math.round(res.totalEnergy);
                }
                else {
                    this.values = res.data.map(el => el.distance);
                    this.average = Math.round(res.avgDistance);
                    this.max = Math.round(res.maxDistance);
                    this.min = Math.round(res.minDistance);
                    this.total = Math.round(res.totalDistance);
                }
                // needs to be last, convert flat value array to array of object with label/value
                if ((this.activeDisplay === "entire") && res.data.length > 0) {
                    let startYear = res.data[0].startDate.year();
                    let i = 0;
                    this.values = this.values.map(el => { return {"label": startYear + i++, "value": el} });
                }
            });
        },
        dismissDialog() {
            this.$modal.hide('statisticsModal');
        },
        displayTab(name) {
            this.activeDisplay = name;
            this.getCurrentLabel();
            this.loadData();
        },
        getCurrentLabel() {
            if (this.activeDisplay === "entire") {
                this.currentLabel = "";
            }
            else if (this.activeDisplay === "week") {
                this.currentLabel = "dayLabel";
            }
            else if (this.activeDisplay === "month"){
                this.currentLabel = "";
            }
            else {
                this.currentLabel = "monthLabel";
            }
        },
        clickBar(ev) {
            let e = ev || window.event;
            if (e.target && e.target.getAttribute('data-index')) {
                if (this.activeDisplay === "entire") {
                    let currentYear = parseInt(moment().year());
                    let selectedYear = (currentYear - this.values.length + parseInt(e.target.getAttribute('data-index')) + 1)
                    this.date = this.date.set('year', selectedYear);
                    this.displayTab("year");
                }
                else if (this.activeDisplay === "year") {
                    this.date = this.date.set('month', parseInt(e.target.getAttribute('data-index')));
                    this.displayTab("month");
                }
                else if (this.activeDisplay === "month") {
                    this.date = this.date.set('date', parseInt(e.target.getAttribute('data-index')) + 1);
                    this.displayTab("week");
                }
                else {
                    this.date = this.date.set('isoWeekday', parseInt(e.target.getAttribute('data-index')) + 1);
                    this.$router.push("/fitnessplan/?date=" + this.date.format('YYYY-MM-DD'));
                }
            }
        },
        //TODO: @Sven this is wip for the overlay. Can be deleted when no longer needed
        displayBarValue() {
            //let graphWidth = this.graphWidth();
            //let barCount = this.values.length;
            // document.getElementById("graphOverlay").style.display = 'block';
            // window.setTimeout(() => {document.getElementById("graphOverlay").style.display = 'none'}, 5000)
        },
        nextDate() {
            if (this.activeDisplay === "year") {
                this.date = moment(this.date).add(1, 'years');
            }
            if (this.activeDisplay === "month") {
                this.date = moment(this.date).add(1, 'months');
            }
            if (this.activeDisplay === "week") {
                this.date = moment(this.date).add(1, 'weeks');
            }
            this.loadData();
        },
        prevDate() {
            if (this.activeDisplay === "year") {
                this.date = moment(this.date).subtract(1, 'years');
            }
            if (this.activeDisplay === "month") {
                this.date = moment(this.date).subtract(1, 'months');
            }
            if (this.activeDisplay === "week") {
                this.date = moment(this.date).subtract(1, 'weeks');
            }
            this.loadData();
        },
        getHours(value) {
            return Math.floor(value / 60);
        },
        getMinutes(value) {
            let minutes = Math.round(value % 60);
            if (minutes > 9) {
                return minutes;
            }
            else {
                return "0" + minutes;
            }
        },
    },
    computed:{
        currentUnit() {
            if (this.statisticName === "Trainingsdauer") {
                return "Std:Min";
            }
            else if (this.statisticName === "Sessions") {
                return "Einheiten";
            }
            else if (this.statisticName === "Aktivitätskalorien") {
                return "kcal";
            }
            else {
                return "km";
            }
        },
        getDisplayDate() {
            if (this.activeDisplay === "year") {
                return moment(this.date).locale('de').format('YYYY');
            }
            if (this.activeDisplay === "month") {
                return moment(this.date).locale('de').format('MMMM YYYY');
            }
            if (this.activeDisplay === "week") {
                let firstDay = moment(this.date).locale('de').startOf('week').format('DD.MM.');
                let lastDay = moment(this.date).locale('de').endOf('week').format('DD.MM.YYYY');
                return firstDay + " - " + lastDay;
            }
            return null;
        },
        showNextDateArrow() {
            if (this.activeDisplay === "year") {
                return this.date < moment().endOf('year').subtract(1, 'years');
            }
            if (this.activeDisplay === "month") {
                return this.date < moment().endOf('month').subtract(1, 'months');
            }
            if (this.activeDisplay === "week") {
                return this.date < moment().endOf('week').subtract(1, 'weeks');
            }
            return true;
        },
        showPrevDateArrow() {
            if (this.activeDisplay === "year") {
                return this.date > this.minDate.add(1, 'year').startOf('year');
            }
            if (this.activeDisplay === "month") {
                return this.date > this.minDate.add(1, 'month').startOf('month');
            }
            if (this.activeDisplay === "week") {
                return this.date > this.minDate.add(1, 'week').startOf('week');
            }
            return true;
        },
    },
    props: {
        statisticName: {
            type: String,
            default() {
                return {};
            }
        },
        selectedActiveDisplay: {
            type: String,
        },
        dateParameter:{
            type: Object,
        },
    },
    data() {
        return {
            activeDisplay: "entire",
            date: null,
            minDate: null,
            total: 0,
            totalHours: 0,
            totalMinutes: 0,
            average: 0,
            max: 0,
            min: 0,
            currentLabel: "",
            values: [],
            startYear: 1999,
        }
    },
}
</script>

<style lang="scss">
    .statistics__detail {
        &__header {
            display: flex;
            align-items: center;
            flex-direction: column;
            padding: 16px 0 38px;

            &__value {
                font-size: 32px;
                font-weight: 700;
                color: $colorBlack;

                span {
                    font-size: 17px;
                }
            }

            &__label {
                display: flex;
                align-items: center;
                font-size: 14px;
                line-height: 14px;
                letter-spacing: -0.21px;
                font-weight: 600;
                color: rgba($colorBlack, .48);
            }
        }

        &__chart {
        //    position: relative;
        //    margin: 0 0 32px 0;
        //    padding: 0;
            height: 220px;
            width: 100%;
            max-width: 576px;
            margin-bottom: 32px;
        }
    }
    //
    //.container {
    //
    //}
    //.chart {
    //    position: absolute;
    //    top: 0;
    //    x: 0;
    //}
    //.overlay {
    //
    //}
</style>

<style lang="scss">
    .vm-header {
        background: $colorWhite;
        position: sticky;
        top: 0;
        min-height: 48px;
        border-radius: 16px 16px 0 0;
    }

    .vm-content {
        padding-top: 0;
        max-width: 560px;
        margin: 0px auto;
    }
</style>