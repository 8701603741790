var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('svg',{staticClass:"pure-vue-bar-chart",attrs:{"width":_vm.fullSvgWidth,"height":_vm.fullSvgHeight,"aria-labelledby":"title","role":"img"}},[(_vm.title)?_c('title',{attrs:{"id":"title"}},[_vm._v(_vm._s(_vm.title))]):_vm._e(),_c('g',{attrs:{"transform":("translate(0," + (_vm.showYAxis ? _vm.extraTopHeightForYAxisLabel : 0) + ")")}},[_c('g',{attrs:{"transform":("translate(" + (_vm.showYAxis ? _vm.yAxisWidth : 0) + ",0)"),"width":_vm.innerChartWidth,"height":_vm.innerChartHeight}},[_vm._l((_vm.chartData),function(ref){
var index = ref.index;
var x = ref.x;
var staticValue = ref.staticValue;
var width = ref.width;
var height = ref.height;
var yOffset = ref.yOffset;
var midPoint = ref.midPoint;
var yLabelOffset = ref.yLabelOffset;
var label = ref.label;
return _c('g',{key:index,attrs:{"transform":("translate(" + x + ",0)")}},[_c('title',[_vm._t("title",function(){return [_c('tspan',[_vm._v(_vm._s(staticValue))])]},{"barIndex":index,"staticValue":staticValue})],2),(width > 32)?_c('rect',{style:({ fill: _vm.barColor }),attrs:{"data-index":index,"width":32,"height":height,"x":(width - 32) / 2,"y":yOffset}}):_c('rect',{style:({ fill: _vm.barColor }),attrs:{"data-index":index,"width":width,"height":height,"x":0,"y":yOffset}}),(_vm.showValues)?_c('text',{attrs:{"x":midPoint,"y":yOffset,"dy":("" + (height < 22 ? '-5px' : '15px')),"text-anchor":"middle"}},[_vm._v(_vm._s(staticValue))]):_vm._e(),(_vm.showXAxis)?_c('g',[_vm._t("label",function(){return [(_vm.chartData.length < 20 || (index % 5 == 4))?_c('text',{staticClass:"pure-vue-bar-chart__labelx",attrs:{"x":midPoint,"y":((yLabelOffset + 10) + "px"),"text-anchor":"middle"}},[_vm._v(" "+_vm._s(label)+" ")]):_vm._e()]},{"barIndex":index,"midPoint":midPoint,"yLabelOffset":yLabelOffset})],2):_vm._e()])}),(_vm.showTrendLine)?_c('line',{attrs:{"x1":_vm.trendLine.x1,"y1":_vm.trendLine.y1,"x2":_vm.trendLine.x2,"y2":_vm.trendLine.y2,"stroke-width":_vm.trendLineWidth,"stroke":_vm.trendLineColor}}):_vm._e()],2),(_vm.showXAxis)?_c('g',[_c('line',{attrs:{"x1":_vm.showYAxis ? _vm.yAxisWidth-1 : 2,"x2":_vm.innerChartWidth + _vm.yAxisWidth,"y1":_vm.innerChartHeight,"y2":_vm.innerChartHeight,"stroke":"#EDEDED","stroke-width":"1"}})]):_vm._e(),(_vm.showYAxis)?_c('g',[_c('line',{attrs:{"x1":_vm.yAxisWidth-1,"x2":_vm.yAxisWidth-1,"y1":_vm.innerChartHeight,"y2":"0","stroke":"#EDEDED","stroke-width":"1"}}),_vm._l((_vm.getTicks()),function(tick,i){return _c('g',{key:i},[_c('text',{staticClass:"text-black",attrs:{"x":"0","y":tick.y1,"alignment-baseline":"central","fill":"black"}},[_vm._v(_vm._s(tick.text))])])})],2):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }