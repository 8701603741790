<template>
  <div class="statistics">

    <!-- TABS -->
    <div class="tabs tabs--fullwidth">
      <div class="tabs__content">
        <v-btn @click="displayTab('entire')" :class="activeDisplay === 'entire' ? 'is-active' : ''" text
               v-ripple="false">Gesamt
        </v-btn>
        <v-btn @click="displayTab('week')" :class="activeDisplay === 'week' ? 'is-active' : ''" text v-ripple="false">
          Woche
        </v-btn>
        <v-btn @click="displayTab('month')" :class="activeDisplay === 'month' ? 'is-active' : ''" text v-ripple="false">
          Monat
        </v-btn>
        <v-btn @click="displayTab('year')" :class="activeDisplay === 'year' ? 'is-active' : ''" text v-ripple="false">
          Jahr
        </v-btn>
      </div>
    </div>

    <!-- Calendar Navigation -->
    <div v-if="activeDisplay !== 'entire'" class="calendar-navigation">
      <div class="calendar-navigation__content">
        <a @click="showPrevDateArrow && prevDate()">
          <div class="calendar-navigation__left">
            <img src="@/assets/ic_nav_back.svg" alt="Preview Date Arrow" :style="showPrevDateArrow ? '' : 'opacity: .12'">
          </div>
        </a>
        <div class="calendar-navigation__center">
          <span>{{ getDisplayDate }} </span>
        </div>
        <a @click="showNextDateArrow && nextDate()">
          <div class="calendar-navigation__right">
            <img src="@/assets/ic_nav_next.svg" alt="Next Date Arrow" :style="showNextDateArrow ? '' : 'opacity: .12'" >
          </div>
        </a>
      </div>
    </div>

    <!-- Leistungsdaten -->
    <div class="list">
      <div class="list__title">
        Leistungsdaten
      </div>
      <div @click="openStatisticsModal('Trainingsdauer')"
           class="challenge__success__statistics__item challenge__success__statistics__item--small challenge__success__statistics__item--dark">
        <img src="@/assets/ic_64_stopclock.svg" alt="Stopclock Image">
        <div class="challenge__success__statistics__item__left">
          <div class="challenge__success__statistics__item__value">
            {{ totalHours }}<span>h</span> {{ totalMinutes }}<span>m</span>
          </div>
          <div class="challenge__success__statistics__item__label">
            Trainingsdauer
          </div>
        </div>
        <div class="challenge__success__statistics__item__right">
          Details
          <img src="@/assets/ic_16_drilldown.svg" alt="Arrow To The Right">
        </div>
      </div>

      <div @click="openStatisticsModal('Aktivitätskalorien')"
           class="challenge__success__statistics__item challenge__success__statistics__item--small challenge__success__statistics__item--dark">
        <img src="@/assets/ic_64_flame.svg" alt="Flame Image">
        <div class="challenge__success__statistics__item__left">
          <div class="challenge__success__statistics__item__value">
            {{ totalEnergy }} <span>kcal</span>
          </div>
          <div class="challenge__success__statistics__item__label">
            Aktivitätskalorien
          </div>
        </div>
        <div class="challenge__success__statistics__item__right">
          Details
          <img src="@/assets/ic_16_drilldown.svg" alt="Arrow To The Right">
        </div>
      </div>

      <div @click="openStatisticsModal('Sessions')"
           class="challenge__success__statistics__item challenge__success__statistics__item--small challenge__success__statistics__item--dark">
        <img src="@/assets/ic_64_workouts.svg" alt="Dumbbell Image">
        <div class="challenge__success__statistics__item__left">
          <div class="challenge__success__statistics__item__value">
            {{ totalCount }} <span>Sessions</span>
          </div>
          <div class="challenge__success__statistics__item__label">
            Trainingseinheiten
          </div>
        </div>
        <div class="challenge__success__statistics__item__right">
          Details
          <img src="@/assets/ic_16_drilldown.svg" alt="Arrow To The Right">
        </div>
      </div>

      <div @click="openStatisticsModal('Distanz')"
           class="challenge__success__statistics__item challenge__success__statistics__item--small challenge__success__statistics__item--dark">
        <img src="@/assets/ic_64_distance.svg" alt="Distance Image">
        <div class="challenge__success__statistics__item__left">
          <div class="challenge__success__statistics__item__value">
            {{ totalDistance }} <span>km</span>
          </div>
          <div class="challenge__success__statistics__item__label">
            Distanz
          </div>
        </div>
        <div class="challenge__success__statistics__item__right">
          Details
          <img src="@/assets/ic_16_drilldown.svg" alt="Arrow To The Right">
        </div>
      </div>
    </div>

    <!-- Beliebteste Workouts -->
    <div v-if="topActivities.length > 0" class="list">
      <div class="list__title">
        Deine beliebtesten Workouts
      </div>
      <div v-for="(activity, i) in topActivities.slice(0, topActivityLimit)" :key="i + 1" class="list__item list__item--background-dark">
        <div class="list__item__icon list__item__icon--image">
          <span>{{ i + 1 }}</span>
          <img v-if="activity.activity.image_url" :src="'https://app.sportlerplus.com/scaled/800/' + activity.activity.image_url" alt="">
          <img v-else src="https://app.sportlerplus.com/scaled/800/bruce-mars-gJtDg6WfMlQ-unsplash.jpg" alt="" >
        </div>
        <div class="list__item__content">
          <div class="list__item__text">
            <div v-if="activity.activity.title" class="list__item__label">
              {{ activity.activity.title }}
            </div>
            <div v-else class="list__item__label">
              {{ activity.activity.name }}
            </div>
          </div>
          <div class="list__item__value">
            {{ activity._useCount }}x
          </div>
        </div>
      </div>
      <div class="list__action" v-if="topActivityLimit < topActivities.length" @click="topActivityLimit = topActivities.length">Alle Anzeigen &ShortDownArrow;</div>
    </div>

    <!-- Challenges -->
    <div v-if="activeDisplay === 'entire'" class="list">
      <div class="list__title">
        Challenges
      </div>
      <div v-if="startedChallenges.length > 0">
        <div v-for="(challenge, i) in getSelectedChallenges" :key="i" class="list__item list__item--background-dark">
          <div class="list__item__icon list__item__icon--image">
            <img :src="'https://app.sportlerplus.com/scaled/800/' + challenge.image_url" alt="">
          </div>
          <div class="list__item__content">
            <div class="list__item__text">
              <div class="list__item__toplabel">
                {{ challenge.badge }}
              </div>
              <div class="list__item__label">
                {{ challenge.title }}
              </div>
            </div>
            <div v-if="challenge.inProgress === true" class="list__item__value">
              <div class="badge badge--positive">Aktiv</div>
            </div>
            <div v-else class="list__item__value">
              <div class="badge ">Bereits absolviert</div>
            </div>
          </div>
        </div>
      </div>

      <!-- Empty-State (if the user has no active or finished challenges) -->
      <div v-else>
        <div v-if="activeDisplay === 'entire'" class="section__empty">
          <img src="@/assets/ic_challenges_empty.svg" alt="">
          <div class="section__empty__text">
            Du hast bisher noch keine Challenge gestartet.
          </div>
          <router-link to="/fitnessplan/challenges" class="button button--primary button--small">Challenges entdecken
          </router-link>
        </div>
      </div>
    </div>

    <!-- Statistik-Modal -->
    <StatisticsModal ref="statisticsModal" :statistic-name="statisticName" :selected-active-display="activeDisplay" :date-parameter="date"></StatisticsModal>

  </div>
</template>

<script>
import StatisticsModal from "@/components/StatisticsModal.vue";
import moment from "moment/moment";

export default {
    name: 'Statistics',
    components: { StatisticsModal },
    data() {
        return {
            startedChallenges: [],
            challenges: [],
            topActivities: [],
            activeDisplay: "entire",
            statisticName: "",
            date: null,
            minDate: null,
            totalHours: 0,
            totalMinutes: 0,
            totalCount: 0,
            totalEnergy: 0,
            totalDistance: 0,
            topActivityLimit: 3,
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init() {
            this.date = moment().locale('de');
            this.loadData();
            await this.$store.dispatch("fetchChallenges").then(() => {
                this.challenges = this.$store.getters.getChallenges;
            });
            await this.$store.dispatch('fetchFitnessPlan').then((res) => {
                if (res.length !== 0 && res[0].startedChallenges) {
                    this.startedChallenges = res[0].startedChallenges;
                }
            });
        },
        openStatisticsModal(statisticName) {
            this.statisticName = statisticName;
            this.$refs.statisticsModal.init();
            this.$modal.show("statisticsModal");
        },
        displayTab(name) {
            this.activeDisplay = name;
            this.loadData();
        },
        nextDate() {
            if (this.activeDisplay === "year") {
                this.date = moment(this.date).add(1, 'years');
            }
            if (this.activeDisplay === "month") {
                this.date = moment(this.date).add(1, 'months');
            }
            if (this.activeDisplay === "week") {
                this.date = moment(this.date).add(1, 'weeks');
            }
            this.loadData();
        },
        prevDate() {
            if (this.activeDisplay === "year") {
                this.date = moment(this.date).subtract(1, 'years');
            }
            if (this.activeDisplay === "month") {
                this.date = moment(this.date).subtract(1, 'months');
            }
            if (this.activeDisplay === "week") {
                this.date = moment(this.date).subtract(1, 'weeks');
            }
            this.loadData();
        },
        loadData() {
            this.$store.dispatch('statisticsData', {startDate: this.date, scope: this.activeDisplay}).then((res) => {
                this.minDate = res.minDate;
                this.totalHours = Math.floor(res.totalDuration / 60);
                this.totalMinutes = Math.round(res.totalDuration % 60);
                this.totalCount = res.totalCount.toLocaleString();
                this.totalEnergy = Math.round(res.totalEnergy).toLocaleString();
                this.totalDistance = Math.round(res.totalDistance).toLocaleString();
                this.topActivities = res.topActivities;
            });
        }
    },
    computed: {
        getDisplayDate() {
            if (this.activeDisplay === "year") {
                return moment(this.date).locale('de').format('YYYY');
            }
            if (this.activeDisplay === "month") {
                return moment(this.date).locale('de').format('MMMM YYYY');
            }
            if (this.activeDisplay === "week") {
                let firstDay = moment(this.date).locale('de').startOf('week').format('DD.MM.');
                let lastDay = moment(this.date).locale('de').endOf('week').format('DD.MM.YYYY');
                return firstDay + " - " + lastDay;
            }
            return null;
        },
        getSelectedChallenges() {
            if (!this.challenges) { return []; }
            let selectedChallenges = [...this.startedChallenges];
            selectedChallenges.forEach(el => {
                Object.assign(el, this.challenges.find(c => parseInt(c.id) === parseInt(el.id)))
            });
            return selectedChallenges;
        },
        showNextDateArrow() {
            if (this.activeDisplay === "year") {
                return this.date < moment().endOf('year').subtract(1, 'year');
            }
            if (this.activeDisplay === "month") {
                return this.date < moment().endOf('month').subtract(1, 'month');
            }
            if (this.activeDisplay === "week") {
                return this.date < moment().endOf('week').subtract(1, 'week');
            }
            return true;
        },
        showPrevDateArrow() {
            if (this.activeDisplay === "year") {
                return this.date > this.minDate.add(1, 'year').startOf('year');
            }
            if (this.activeDisplay === "month") {
                return this.date > this.minDate.add(1, 'month').startOf('month');
            }
            if (this.activeDisplay === "week") {
                return this.date > this.minDate.add(1, 'week').startOf('week');
            }
            return true;
        },
    }
}
</script>

<style lang="scss">
  .badge {
    position: relative;
    z-index: 1;
    margin-top: auto;
    display: inline-flex;
    font-size: 11px;
    line-height: 11px;
    font-weight: 700;
    letter-spacing: -0.13px;
    text-transform: uppercase;
    padding: 4px 5px 3px 5px;
    margin-bottom: 5px;
    border-radius: 2px;
    color: $colorWhite;
    background: $colorPrimary;

    &--positive {
      background: $colorSuccess;
    }
  }
</style>

<style lang="scss" scoped>
  /* OVERWRITES / ADDITIONAL CODE */

  .tabs {
      margin-top: -24px;
  }

  header {
    position: relative !important;
    margin-bottom: -44px;
  }

  .list {
    margin-bottom: 16px;
  }

  .list__action {
    font-size: 11px;
    line-height: 14px;
    letter-spacing: -0.16px;
    font-weight: 600;
    color: rgba(30, 29, 31, 0.48);
    text-align: center;
  }
  .list__title {
    display: flex;

    span {
      margin-left: auto;
    }
  }

  .list__item__icon {
    span {
      position: absolute;
      width: 16px;
      height: 16px;
      right: 0;
      bottom: 0;
      background: $colorPrimary;
      color: $colorWhite;
      text-align: center;
      font-size: 11px;
      font-weight: 700;
      letter-spacing: -0.16px;
      border-radius: 2px 0 0 0;
    }
  }

  .list__item__label--light {
    opacity: .48;
  }

  .list__item, .challenge__success__statistics__item {
    cursor: pointer;
    transition: all .2s ease-in-out;
    background: $colorWhite;
    border-radius: 4px;
    box-shadow: 0 8px 16px 0 rgba($colorBlack,0.04), inset 0 0 0 1px rgba($colorBlack, .08) !important;


    &:hover {
      box-shadow: 0 8px 16px 0 rgba($colorBlack,0.08), inset 0 0 0 1px rgba($colorBlack, .08);
    }

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  .section__empty {
    width: 100%;
    margin: 0;
    background: rgba($colorBlack, .04);

    &__text {
      color: rgba($colorBlack, .32);
    }
  }
</style>